import React from "react";
import { Link } from "gatsby";

const RelatedRateOptions = ({
  heading,
  headingLevel,
  hiddenRate,
  className,
gridHeading,
  gridCols,
}) => {
  const HeadingTag = headingLevel || "h2";


  return (
    <section
      className={`bg-primary-50 py-20 md:py-32 ${
        className || "mb-20 md:mb-32"
      }`}
    >
      <div className="container">
        <header className="mb-10 text-center md:mb-16">
          <HeadingTag>{heading || "Other Rate Options"}</HeadingTag>
        </header>
        <div
          className={`grid md:grid-cols-3 gap-10 ${
            gridCols == true && "md:grid-cols-4"
          }`}
        >
          <Link
            to="/adjustable-rate-mortgage/"
            className={`items-center overflow-hidden rounded-3xl bg-white px-6 py-8 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10 ${
              hiddenRate && hiddenRate === 1 && "!hidden"
            }`}
          >
            <h3
              className={`heading-four mb-2 text-secondary-500 ${
                gridHeading == true && "heading-six"
              }`}
            >
              Fixed Rate
            </h3>
            <p className="mb-0">
              A fixed-rate mortgage keeps the same interest rate throughout the
              life of the loan (i.e., 15 or 30 years).
            </p>
          </Link>

          <Link
            to="/adjustable-rate-mortgage/"
            className={`items-center overflow-hidden rounded-3xl bg-white px-6 py-8 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10 ${
              hiddenRate && hiddenRate === 2 && "!hidden"
            }`}
          >
            <h3
              className={`heading-four mb-2 text-secondary-500 ${
                gridHeading == true && "heading-six"
              }`}
            >
              Adjustable ARM
            </h3>
            <p className="mb-0">
              The interest rate adjusts to current market rates during set
              periods throughout the life of the loan.
            </p>
          </Link>

          <Link
            to="/interest-only-mortgage/"
            className={`items-center overflow-hidden rounded-3xl bg-white px-6 py-8 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10 ${
              hiddenRate && hiddenRate === 3 && "!hidden"
            }`}
          >
            <h3
              className={`heading-four mb-2 text-secondary-500 ${
                gridHeading == true && "heading-six"
              }`}
            >
              Interest Only
            </h3>
            <p className="mb-0">
              A borrower pays only the interest rate for a certain loan term
              period, typically for the first few years.
            </p>
          </Link>

          <Link
            to="/graduated-payment-mortgage/"
            className={`items-center overflow-hidden rounded-3xl bg-white px-6 py-8 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl md:px-8 md:py-10 ${
              hiddenRate && hiddenRate === 4 && "!hidden"
            }`}
          >
            <h3
              className={`heading-four mb-2 text-secondary-500 ${
                gridHeading == true && "heading-six"
              }`}
            >
              Graduated Payments
            </h3>

            <p className="mb-0">
              Monthly payments start at a set amount and gradually increase over
              the life of the loan.
            </p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RelatedRateOptions;
