import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ValueProps = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Personalized Experience.svg"
        }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Easy Loan Process.svg"
        }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Family Owned _ Operated.svg"
        }
      ) {
        publicURL
      }
      icon4: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Experts Since 1989.svg"
        }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
      icon: data.icon1.publicURL,
      heading: "Personalized Experience",
      text: "We take the time to get to know you and your goals, then help you every step of the way.",
    },
    {
      icon: data.icon2.publicURL,
      heading: "Easy Loan Process",
      text: "Close your loan in five easy steps—from finding out how much you can borrow to signing the final documents.",
    },
    {
      icon: data.icon3.publicURL,
      heading: "Family Owned & Operated",
      text: "We take a personalized approach with every client and have built a loyal ‘fans for life’ following.",
    },
    {
      icon: data.icon4.publicURL,
      heading: "Experts Since 1989",
      text: "With 30+ years of experience, we know what we’re doing, and we believe in transparency, trust, and excellent customer service.",
    },
  ];

  return (
    <section className={`${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <header className="mb-10 md:mb-16">
          <HeadingTag>Why Right Start Is the Right Choice</HeadingTag>
        </header>

        <div className="relative z-10 grid gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div key={i}>
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mb-3"
                />
                <div>
                  <p className="heading-six mb-3">{content.heading}</p>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
