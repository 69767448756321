import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import RelatedRateOptions from "../components/Repeating/RelatedRateOptions";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Fixed Rate Mortgage | Right Start | Mortgage Lender"
        description="Learn about the benefits of a fixed-rate mortgage, which carries a set interest rate for the lifetime of your home loan. Call today to request rates!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative overflow-hidden py-20 md:py-28">
        <div className="absolute left-0 top-0 h-full w-full ">
          <StaticImage
            src="../images/3.0 Mortgage Rate Options/Hero.jpg"
            loading="eager"
            className="h-full"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[640px]">
            <h1 className="text-white">Fixed Rate Mortgage</h1>
            <p className="mb-0 text-white">
              If you’re looking for a monthly mortgage payment that doesn’t
              fluctuate, providing you with stability in your budget, choose a
              fixed-rate mortgage.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="What is it?"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="How it works"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Timeframe"
                  stripHash
                />
              </li>

              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Examples"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">What is a Fixed-Rate Mortgage?</h2>
                <p className="mb-0">
                  A fixed-rate mortgage carries a set interest rate for the
                  lifetime of the loan, and the rate will not be impacted by
                  market fluctuations. Your monthly mortgage payment will always
                  remain the same, making budgeting easy.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Does a Fixed-Rate Mortgage Work?
                </h2>
                <p className="mb-0">
                  You’ll pay back both principal (a portion of the amount you
                  borrowed) plus interest each month based on an amortization
                  formula and payment schedule. If you make all of your payments
                  according to the schedule, the loan will be paid off at the
                  end of the term, and you’ll own the home free and clear.
                </p>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  How Long Does a Fixed-Rate Mortgage Last?
                </h2>
                <p className="mb-0">
                  Fixed-rate mortgages can run anywhere from 10 to 30 years. The
                  typical increments are 10, 15, 20, and 30 years (30 years is
                  the most popular choice, followed by 15 years). Borrowers who
                  opt for a 15-year term typically receive a lower interest
                  rate, can save tens of thousands of dollars over the life of
                  the loan, and pay off the debt in half the time.
                </p>
              </div>

              <div
                id="section-4"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h2 className="heading-four">
                  Types of Fixed-Rate Mortgage Loans
                </h2>
                <ul className="styled-list-checkmark">
                  <li>FHA Loan</li>
                  <li>VA Loan</li>
                  <li>USDA Loan</li>
                  <li>Jumbo Loan</li>
                  <li>Conventional Loan</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <ValueProps />
      <About />
      <RelatedRateOptions hiddenRate={1} />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
