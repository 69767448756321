import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonIcon from "../Button/ButtonIcon";

const About = ({ headingLevel, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section className={` ${className || "mb-20 md:mb-32"}`}>
      <div className="container">
        <div className="grid items-center gap-y-12 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          <div>
            <StaticImage
              src="../../images/0.0 Repeating Modules/About Us/About Us.png"
              loading="lazy"
              width={560}
            />
          </div>
          <div>
            <HeadingTag>Get to Know Us</HeadingTag>
            <p>
              We’re a family-owned and operated company with a solid team of
              qualified and enthusiastic mortgage specialists committed to
              personalized, individual service for each client. We educate,
              communicate, and are transparent about the loan process to make
              the experience as smooth as possible.
            </p>
            <ButtonIcon
              href="/meet-the-team/"
              text="Meet the Team"
              altStyle={2}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
